import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Modal from "react-bootstrap/Modal"
import Carousel from "react-bootstrap/Carousel"
import Button from "react-bootstrap/Button"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import "bootstrap/dist/css/bootstrap.min.css"

import "./portfolio.scss"

import imgQuote from "../images/quotation_mark.webp"

const portfolioItems = [
  {
    portfolioTitle: "Hospitality",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2020/09/thumb-hopitality@2x.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-hospitality.svg",
    portfolioModalImage:
      "https://www.meritmile.com/uploads/2020/09/citrix-website@2x.png",
    portfolioModalCopy:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia vestibulum.",
    portfolioUrl: "/portfolio/industry/hospitality/abc-global-services/",
    portfolioLinkUrl: "/case-studies/anywhereapp/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-other-b2c-abc-global.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-hospoitality-the-ledge.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-direct-mail-via-mizner.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Financial Services",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2020/09/thumb-financial-services@2x.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-financial-services.svg",
    portfolioUrl: "/portfolio/industry/financial-services/b2r-finance/",
    portfolioLinkUrl: "/case-studies/boca-jets-lacrosse/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-financial-services-b2r.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-financial-services-dwell.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Technology",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2020/09/thumb-technology@2x.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-technology.svg",
    portfolioUrl: "/portfolio/industry/technology/dxc/",
    portfolioLinkUrl: "/case-studies/citrix/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-technology-dxcsparc.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-technology-fujitsu.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-technology-insight.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-technology-supply-the-demand.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Healthcare",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2020/09/thumb-healthcare@2x.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-healthcare.svg",
    portfolioUrl: "/portfolio/industry/healthcare/lifesync/",
    portfolioLinkUrl: "/case-studies/equifax/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-healthcare-lifesync.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-healthcare-md-live.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Consumer Products",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2020/09/thumb-consumer-products@2x.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-consumer-products.svg",
    portfolioUrl: "/portfolio/industry/consumer-products/altier-jewelers/",
    portfolioLinkUrl: "/case-studies/fujitsu-and-microsoft/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-consumer-products-altier.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-consumer-products-quackskins.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Automotive",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2020/09/thumb-automotive@2x.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-automotive.svg",
    portfolioUrl: "/portfolio/industry/automotive/hearst-black-book/",
    portfolioLinkUrl: "/case-studies/black-book/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-automotive-black-book.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-automotive-motor.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-automotive-swapalease.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Other-B2B",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2020/09/thumb-other-b2b@2x.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-otherb2b.svg",
    portfolioUrl: "/portfolio/industry/other-b2b/boca-harbour-capital/",
    portfolioLinkUrl: "/case-studies/hpe/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-other-b2b-boca-harbour.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-other-b2b-device-bits.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-other-b2b-waiv.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Non-Profit",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/12/brhs-cs-thumb.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-non-profit.svg",
    portfolioUrl: "/portfolio/industry/non-profit/boca-raton-historical-society/",
    portfolioLinkUrl: "/case-studie/insight-and-microsoft/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2022/01/thumb-branding-boca-raton-historical-society.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-non-profit-swing-ding.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-non-profit-wine-opener.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Other-B2C",
    portfolioDesc: "Some desc",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2020/09/thumb-other-b2c@2x.jpg",
    portfolioBottomLogo:
      "https://www.meritmile.com/uploads/2020/09/icon-otherb2c.svg",
    portfolioUrl: "/portfolio/industry/other-b2c/iridium/",
    portfolioLinkUrl: "/case-studie/mdlive/",
    portfolioCategories: ["industry"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2023/03/iridium-thumb-1.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-other-b2c-abc-global.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-other-b2c-laure-nell.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Advertising",
    portfolioDesc:
      "Black Book, a division of Hearst Transportation, powers the automotive industry with the most precise data and analtyics in the market. For more on the Black Book and Merit Mile advertising and public relations partnership, please read the <a href='#'>case study</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2023/05/thumb-advertising-iridium.jpg",
    portfolioUrl: "/portfolio/media/advertising/iridium/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2023/03/iridium-thumb-1.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-advertising-bb.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-advertising-efx.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-advertising-protective.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Branding",
    portfolioDesc:
      "We live in an as-as-service world and AnywhereApp delivers modern solutions for businesses embracing the digital economy. From branding, to web design, to PR; read more about the AnywhereApp and Merit Mile collaboration in the case study <a href=https://www.meritmile.com/case-studies/anywhereapp/>here</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2023/05/thumb-branding-intelliva.jpg",
    portfolioUrl: "/portfolio/media/branding/intelliva/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2023/04/thumb-branding-intelliva.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2022/01/thumb-branding-boca-raton-historical-society.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-branding-anywhere-app.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-branding-boca-jets.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-branding-the-ledge.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Broadcast & Video",
    portfolioDesc:
      "In the global cloud services business, competition is fierce. For DXC.Technology, Merit Mile crafted an end-to-end awareness and demand generation campaign focused mainly on differentiating DXC from the competition and recruiting new partners. Check out the video <a href=https://www.youtube.com/watch?v=wHG54VyFJgc>here</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/02/thumb-broadcast-video.jpg",
    portfolioUrl: "/portfolio/media/broadcast-video/iridium/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2023/06/thumb-broadcast-video-iridium.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2023/06/thumb-broadcast-video-microsoft.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2023/06/thumb-broadcast-video-lifesync.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-broadcast-video-dxc.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-broadcast-video-microsoft.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-broadcast-video-tsystems.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Channel Marketing/Sales Enablement",
    portfolioDesc:
      "Citrix and Google Cloud have benefited from a deep, integrated engineering partnership since 2011. This Sales accelerator eBook showcases joint solutions and new technology innovations to help sellers start the right conversations and close deals faster by becoming a trusted advisor on the comprehensive Citrix + Google Cloud solution. To discuss eBooks and more please drop us a note <a href='mailto:Citrix@MeritMile.com?subject=Citrix Google eBook Inquiry'>here</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/02/thumb-channel-marketing.jpg",
    portfolioUrl: "/portfolio/media/channel-marketing-sales-enablement/microsoft-cognizant-ebook/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-channel-marketing-citrix-ebook1.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-channel-marketing-citrix-ebook2.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-channel-marketing-cognizant.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-channel-marketing-nordcloud-ebook.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Direct Mail",
    portfolioDesc:
      "If the customer is king, why do so many IT storage vendors have rigid requirements on hardware, software, platform preferences, and devices? DataCore Software allows users to stand up long range storage strategies without being beholden to disparate equipment and device incompatibilities. For more on Merit Mile direct mail capabilities, please email us <a href='mailto:Hello@MeritMile.com?subject=DataCore Direct Mail Inquiry'>here</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/02/thumb-direct-mail.jpg",
    portfolioUrl: "/portfolio/media/direct-mail/datacore/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-direct-mail-datacore.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-direct-mail-msft-csp.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-direct-mail-via-mizner.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Infographics",
    portfolioDesc:
      "If you have a story to tell and can reinforce the narrative with fresh data points, an infographic is a compelling way to communicate to B2B audiences. For more on Merit Mile Outdoor Retail Marketing, read the research report <a href=' https://www.meritmile.com/outdoor-retail-marketing/'>here</a>.",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2023/05/thumb-infographic-microsoft.jpg",
    portfolioUrl: "/portfolio/media/infographics/hitachi-solutions-digital-front-door/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2022/11/thumb-infographics-hitachi-solutions-dfd.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-infographics-mm-outdoor-retail.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-infographics-efx-work-number.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-infographics-citrix-teams.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2022/08/thumb-infographics-hitachi-solutions.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Interactive/Web",
    portfolioDesc:
      "Formerly known as Hewlett-Packard Enterprise, DXC.Technology has taken a bold branding leap with striking investments in black and white. And did you know DXC really stands for 'digital transformation company'? For more, please email us <a href='mailto:DXC@MeritMile.com?subject=Teams DXC Web Design Inquiry'>here</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/02/thumb-interactive-web.jpg",
    portfolioUrl: "/portfolio/media/interactive-web/dxc-website/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-interactive-web-dxc-sparc.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-interactive-web-lifesync.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-interactive-web-softnas.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Lead Generation",
    portfolioDesc:
      "DXC.Technology SLMS remains an IT channel mainstay and cloud services distribution leader. When the need to recruit partners into their channel arose, Merit Mile concepted and executed a digital campaign that leveraged email, a custom cost savings online calculator, and LinkedIn advertising primarily. For more, including campaign results, please email us <a href='mailto:DXC@MeritMile.com?subj=Teams DXC Lead Gen Inquiry'>here</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2023/05/thumb-leadgen-equifax.jpg",
    portfolioUrl: "/portfolio/media/lead-generation/microsoft-nuance-lead-generation/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-lead-gen-dxc.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-lead-gen-jdl.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-lead-gen-live-action.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-lead-gen-md-live.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Public Relations",
    portfolioDesc:
      "CompliancePoint is a leading provider of information security and risk management services focused on privacy, data security, compliance and vendor risk management. However, as a smaller consultative firm, they struggled to insert themselves as an industry voice amongst national news stories. With focus on storyline ideation and consistent editorial relationship development, Merit Mile continues to position CompliancePoint as an authoritative figure in the privacy, compliance, and GDPR space--to the tune of 31X Return on PR Investment. For more, please <a href='mailto:PR@MeritMile.com?subject=CompliancePoint PR Portfolio Inquiry'>reach out to us</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/02/thumb-pr.jpg",
    portfolioUrl: "/portfolio/media/public-relations/compliancepoint/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-pr-compliance-point.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-pr-equifax.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-pr-kddia.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Research",
    portfolioDesc: "Need copy",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/02/thumb-research.jpg",
    portfolioUrl: "/portfolio/media/research/microsoft/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-research-msft-abc.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-research-bb.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-research-auto-mindshare.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Social Media",
    portfolioDesc:
      "Reinvigorating athletic brands is always fun, but when you add cool uniforms and branded gear to the mix, it's a professional treat! Read more about the Merit Mile and Boca Jets Lacrosse branding and creative partnership in the case study <a href='https://www.meritmile.com/case-studies/boca-jets-lacrosse/'>here</a>",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/02/thumb-social-media.jpg",
    portfolioUrl: "/portfolio/media/social-media/boca-jets-lacrosse/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-social-boca-jets.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-social-dxc.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-social-efx0.jpg",
      },
    ],
  },
  {
    portfolioTitle: "Trade Shows & Booth Designs",
    portfolioDesc: "Need copy",
    portfolioBGImage:
      "https://www.meritmile.com/uploads/2021/02/thumb-trade-shows.jpg",
    portfolioUrl: "/portfolio/media/trade-shows-booth-designs/citrix/",
    portfolioLinkUrl: "",
    portfolioCategories: ["media"],
    portfolioImages: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-trade-show-citrix.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-trade-show-fenix-parts.jpg",
      },
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-trade-show-microsoft.jpg",
      },
    ],
  },
]

class PortfolioThumb extends Component {
  render() {
    this.setState = {
      desc: this.props.desc,
      title: this.props.title,
      slides: this.props.slides,
      portfoliourl: this.props.portfoliourl,
    }
    
    const divStyles = {
      backgroundImage: "url(" + this.props.image + ")",
    }
    const divStylesIconTop = {
      backgroundImage: "url(" + this.props.imagetop + ")",
    }
    const divStylesIconBottom = {
      backgroundImage: "url(" + this.props.imagebottom + ")",
    }
    const categoriesList = this.props.categories
    let cats = categoriesList.join(" ")
    return (
      <div
        className={`gallery_product col-lg-4 col-md-6 col-sm-6 col-12 filter ${cats}`}
      >
        <Link to={this.props.portfoliourl} className="link-wrapper" style={divStyles}>
          <div className="overlay">
            <div className="icon" style={divStylesIconBottom}>
              &nbsp;
            </div>
            <div className="title">
              <div className="wrap">
                <span
                  dangerouslySetInnerHTML={{ __html: this.props.title }}
                ></span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

class MyVerticallyCenteredModal extends Component {
  static defaultProps = {
    slides: [
      {
        src:
          "https://www.meritmile.com/uploads/2021/02/thumb-trade-show-citrix.jpg",
      },
    ],
  }

  render() {
    const { title, desc, slides } = this.props
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="portfolioModal">
          <Carousel>
            {slides.map((e, i) => (
              <Carousel.Item key={i} data-pause="true">
                <img className="d-block w-100" src={e.src} alt={title} />
              </Carousel.Item>
            ))}
          </Carousel>
          <p>&nbsp;</p>
          <h4>{title}</h4>
          <Carousel.Caption dangerouslySetInnerHTML={{ __html: desc }} />
        </Modal.Body>
      </Modal>
    )
  }
}

class PortfolioItemsModule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalShow: false,
      categories: ["industry", "media"],
      selectedPortfolio: "",
      selectedPortfolioDesc: "",
      selectedPortfolioImages: [
        {
          src:
            "https://www.meritmile.com/uploads/2021/02/thumb-trade-show-citrix.jpg",
        },
      ],
    }

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.onPortClick = this.onPortClick.bind(this)
  }

  handleClose() {
    this.setState({
      modalShow: false,
      selectedPortfolio: "",
      selectedPortfolioDesc: "",
      selectedPortfolioImages: [
        {
          src:
            "https://www.meritmile.com/uploads/2021/02/thumb-trade-show-citrix.jpg",
        },
      ],
    })
  }

  handleShow(title, desc, slides) {
    this.setState({
      selectedPortfolio: title,
      selectedPortfolioDesc: desc,
      selectedPortfolioImages: slides,
      modalShow: true,
    })
  }

  componentDidMount() {
    let buttonInit = document.getElementsByClassName("filter-button")[0]
    buttonInit.style.color = "#ffffff"
    buttonInit.style.backgroundColor = "#000000"
    this.setState({
      categories: ["media"],
    })
    this.setState({ modalShow: false })
  }

  onPortClick(e) {
    let buttons = document.getElementsByClassName("filter-button")
    console.log(buttons)
    for (let f = 0; f < buttons.length; f++) {
      buttons[f].style.color = "#000000"
      buttons[f].style.backgroundColor = "#ffffff"
    }
    e.currentTarget.style.color = "#ffffff"
    e.currentTarget.style.backgroundColor = "#000000"
    if (e.currentTarget.getAttribute("data-filter") === "all") {
      this.setState({
        categories: ["media", "industry"],
      })
    } else {
      this.setState({
        categories: [e.currentTarget.getAttribute("data-filter")],
      })
    }
  }
  render() {
    let modalClose = () =>
      this.setState({
        selectedPortfolio: "",
        selectedPortfolioDesc: "",
        selectedPortfolioImages: [
          {
            src:
              "https://www.meritmile.com/uploads/2021/02/thumb-trade-show-citrix.jpg",
          },
        ],
        modalShow: false,
      })

    return (
      <div className="container portfolio-items">
        <div className="row">
          <div className="col">
            <ButtonToolbar>
              <MyVerticallyCenteredModal
                show={this.state.modalShow}
                onHide={modalClose}
                title={this.state.selectedPortfolio}
                desc={this.state.selectedPortfolioDesc}
                slides={this.state.selectedPortfolioImages}
              />
            </ButtonToolbar>
            <div align="center" className="filter-btns">
              <button
                className="btn btn-default filter-button"
                onClick={this.onPortClick}
                data-filter="media"
              >
                MEDIA
              </button>
              <button
                className="btn btn-default filter-button"
                onClick={this.onPortClick}
                data-filter="industry"
              >
                INDUSTRY
              </button>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          {portfolioItems
            .filter(e => {
              for (let i = 0; i < e.portfolioCategories.length; i++) {
                if (this.state.categories.includes(e.portfolioCategories[i])) {
                  return this.state.categories.includes(
                    e.portfolioCategories[i]
                  )
                }
              }
              return null
            })
            .map((e, i) => {
              return (
                <PortfolioThumb
                  modalShow={this.handleShow}
                  key={i}
                  image={e.portfolioBGImage}
                  imagetop={e.portfolioIcon}
                  imagebottom={e.portfolioBottomLogo}
                  url={e.portfolioUrl}
                  categories={e.portfolioCategories}
                  slides={e.portfolioImages}
                  title={e.portfolioTitle}
                  desc={e.portfolioDesc}
                  portfoliourl={e.portfolioUrl} 
                />
              )
            })}
        </div>
      </div>
    )
  }
}

export default ({ pageContext }) => (
  <Layout>
    <SEO
      title={pageContext.yoast.title}
      description={pageContext.yoast.metadesc}
      ogimg={pageContext.yoast.opengraph_image.source_url}
      link={pageContext.link}
    />
    <div className="portfolio-wrapper">
      <div
        className="container-fluid header-container"
        style={{
          backgroundImage: `url(${pageContext.acf.header_image.localFile.childImageSharp.fluid.src})`,
        }}
      >
        <div className="row">
          <div className="container">
            <div className="row">
              <h2>{pageContext.acf.header_text}</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <article className={`${pageContext.slug}`}>
        <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
      </article>
      <PortfolioItemsModule />
      <div className="container quote-wrapper">
        <div className="row">
          <div className="col-12">
            <p>{pageContext.acf.quote_text}</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
